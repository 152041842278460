import Vue from "vue";
import App from "./App.vue";
import vuetify from "@/plugins/vuetify";
import VueScrollReveal from "vue-scroll-reveal";
import VueParallaxJs from "vue-parallax-js";
import i18n from "../../i18n.js";

Vue.config.productionTip = false;
Vue.use(VueParallaxJs);
Vue.use(VueScrollReveal, {
  class: "v-scroll-reveal",
  duration: 1500,
  scale: 1,
  distance: "10px",
  mobile: true,
});

new Vue({
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
